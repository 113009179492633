.wrap {

margin: 0 auto;
width: 80%;
max-width: 1000px;

}

.navbarwrap {

margin: 0 auto;
width: 90%;
max-width: 1000px;

}


h1 {
	font-weight: 800;
}

h2 {
	font-weight: 800;
}


.columnimages {	
display: block; 
height: 200px; 
background-color: #262626;
background-repeat: no-repeat center center fixed; 
-moz-background-size: cover;
-moz-background-size: cover;
background-size: cover;
background-position: 50% 0%;
transition-duration: 0.3s;
transition-property: transform;
}


.columnimages:hover {
	transform: scale(1.1);
}



/* ************************************** 

             Image Grow Effect

************************************** */

.hvr-grow {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
    transform: scale(1.1);
}


/* ************************************** 

               Index Slider

************************************** */

.slider-box {
display: block;
position: relative;
background-color: transparent;
margin-top: 20px; 
margin-bottom: 20px;
	
}

/* ************************************** 

               HR Styles

************************************** */

hr.style18 { 
  height: 30px;
  border-style: solid; 
  border-color: #004E59; 
  border-width: 1px 0 0 0; 
  border-radius: 20px; 
  max-width: 90%;
} 
hr.style18:before { 
  display: block; 
  content: ""; 
  height: 30px; 
  margin-top: -31px; 
  border-style: solid; 
  border-color: #004E59; 
  border-width: 0 0 1px 0; 
  border-radius: 20px; 
}

/* ************************************** 

               arrows

************************************** */

.arrow {
  fill:none;
  stroke-width:9;
  stroke-linejoin:round; 
  stroke-miterlimit:10;
  stroke-dasharray: 479.85 479.85;
  stroke-dashoffset: 479.85;
}

/* ************************************** 

               Hero Index

************************************** */

.hero {
	color: #004E59;
	padding: 80px 0px;
	background-color: rgba(255, 255, 255, .8);
}

.hero h1 {
	color: #004E59;
	font-weight: 900;
}



.hero .button {
	background-color: #004E59;
}

.hero .button:hover {
	background-color: #004E59;
	opacity: 0.8;
}


/* ************************************** 

           Hero Index Small

************************************** */

.hero-small {
	background-color: #012532;
	padding: 80px 0px;
background-image: url("http://www.sentrybuilding.com/dev/assets/img/hero-image.jpg");		background-size: 100%;
	background-position-y: 0px;
	background-repeat: no-repeat;
	
	color: rgba(255, 255, 255, .9);
}

.hero-small h1 {
	color: #004E59;
	font-weight: 900;
}

.hero-small p {
	max-width: 90%;
}

.hero-small .button {
	background-color: #004E59;
}

.hero-small .button:hover {
	background-color: #004E59;
	opacity: 0.8;
}

/* ************************************** 

              Hero Products

************************************** */
.hero-products {
	background-color: #004E59;
	padding: 80px 0px;
	background-image: url("http://www.sentrybuilding.com/dev/assets/img/hero-roofnado.jpg");
	background-size: 100%;
	background-repeat: no-repeat;
		
	color: rgba(255, 255, 255, .9);
}

.hero-products h1 {
	color: #004E59;
	font-weight: 900;
}



.hero-products .button {
	background-color: #004E59;
}

.hero-products .button:hover {
	background-color: #004E59;
	opacity: 0.8;
}


/* ************************************** 

         Hero Products Small

************************************** */
.hero-products-small {
	background-color: #012532;
	padding: 80px 0px;
	background-image: url("http://www.sentrybuilding.com/dev/assets/img/hero-roofnado.jpg");
	background-size: 100%;
	background-position-y: 0px;
	background-repeat: no-repeat;
		
	color: rgba(255, 255, 255, .9);
}

.hero-products-small h1 {
	color: #004E59;
	font-weight: 900;
}



.hero-products-small .button {
	background-color: #004E59;
}

.hero-products-small .button:hover {
	background-color: #004E59;
	opacity: 0.8;
}


/* ************************************** 

                 Main Section

************************************** */

.main {
padding: 80px 0px;
background-color: #FFFFFF;
	
}




/* ************************************** 

                 Footer

************************************** */

footer {
background-color: #004E59;
	padding: 80px 0px;
		
}

footer a {
	display: block;
	color: rgba(255, 255, 255, .85);
	font-size: 0.9em;
}

footer a:hover {
	color: rgba(255, 255, 255, .6);
}

 footer hr {
 	margin: 10px 0;
 	border: 1px solid #004E59;
 	opacity: 0.4;
 }

footer h4 {
	color: #004E59;
	font-size: 1.3em;
}

footer a span {
	font-weight: bold;
	color: rgba(255, 255, 255, .5);
}

.copyright {
vertical-align: middle;
width: 100%;
text-align: center;
display: block;
height: 80px; 
background-color: #002d33;
color: rgba(255, 255, 255, 0.65);	
font-size: 0.7em;
padding-top: 35px;
}

.footer-logo {
width: 250px;
margin-top: 0px;
margin-left: 0px;
padding-bottom: 10px;

}

.apex_footer-logo {
width: 250px;
margin-top: 15px;
margin-left: 0px;	
padding-bottom: 30px;

}

.footer-logo-sml {
width: 200px;
margin-top: 0px;
padding-bottom: 30px;

}

.apex-footer-logo-sml {
width: 230px;
margin-top: 0px;
padding-bottom: 30px;

}

/* ************************************** 

                 Cards

************************************** */



.card-divider  {
	background-color: #012532;
	color: rgba(250, 189, 74, .95);
	font-weight: bold;
	text-align: center;
	padding: 20px 0px 20px 0px;
	
}

.card-section {
	background-color: #fff;
	color: rgba(0, 0, 0, .85);
	border: none;
	
}

.card-section h4 {
	font-weight: bold;
}

.card {
	border: 1px solid #fff;
	
}


