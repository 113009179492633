nav {
	background: #004E59;
	height: 50px;
}


/* ************************************** 

     Sentry Desktop Menu with Dropdowns

************************************** */

.nav-desktop {
background-color: #004E59;
display: block;
position: relative;
margin-top: 20px;
height: 80px;
padding: 0;
}
	
.nav-desktop .sentry-desktop-dropdown ul {
	border: none;
	width: 300px;
}

.nav-desktop .sentry-desktop-dropdown {
	background-color: transparent;
}

.nav-desktop .sentry-desktop-dropdown ul li a {
border-bottom: 1px solid rgba(1, 37, 50, 0.55);
line-height: 50px;
	
	}

.nav-desktop .sentry-desktop-dropdown a {
	display: inline-block;
	width: 100%;
	line-height: 80px;
	color: rgba(255, 255, 255, 0.8);
	padding:0px 10px 0px 10px;
}

.nav-desktop .sentry-desktop-dropdown a:hover {
background-color: rgba(255, 255, 255, 0.25);
}


.nav-desktop .sentry-desktop-dropdown ul li a{
	background-color: #06f9d6;
	color: #004E59;
}

.nav-desktop .sentry-desktop-dropdown ul li a:hover{
  background-color: #9cfcee}


* {
	outline: none;
}

.site-logo {
	display: block;
	height: 80px;
	width: 200px;
	margin-top: 5px;
	background-image: url(../img/netmatrix-logo-white.png);
	background-repeat: no-repeat;
}

.site-logo:hover {
	opacity: 0.8;
}

.site-logo {
		color: #FABD4A;
		line-height: 80px;
}



.offcanvas-site-logo {
	display: block;
	width: 200px;
	padding-left: 20px;
	padding-top: 10px;
	
	
}



/* ************************************** 

                 Mobile Menu

************************************** */
.off-canvas-wrapper {
	background-color: #004E59;
}

.nav-mobile {

	background-color: #004E59;
	color: #16B1A3;
	line-height: 60px;
	height: 60px;
	padding: 0;
	padding-left: 20px;
	margin-top: -1px;
	}
	
	
.nav-mobile ul {

	border-top: 2px solid #16B1A3;
	margin-top: 10px;
	}
					

#mobile-menu {
	background-color: #004E59;
	color: rgba(255, 255, 255, .85);	
}	

#mobile-menu a{
	color: rgba(255, 255, 255, .85);
	padding: 10px;	
}
	

#mobile-menu li{
display: inline;
	list-style: none;
	padding: 0px;
}

#mobile-menu li:hover{
  background-color: #16B1A3;
	
}

#mobile-menu a:hover{
		background-color: #16B1A3;
		color: #012532;
		
}



