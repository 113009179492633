 .highlights {
	background-color: rgba(250, 189, 74, 0.15);
	padding: 20px;
	margin-top: 85px;
}

.locations {
	background-color: rgba(250, 189, 74, 0.15);
	padding: 20px;
}

.locations h5 {
	font-weight: bold;
}




.highlights h3 {
	font-weight: 700;
}

.hrmail {
	color: #FABD4A;
}

.hrmail:hover {
color: #FABD4A;
	opacity: 0.5;
}

.hrmail:visited {
color: #FABD4A;
}

.mask {
	display: block;
	position: relative;
	z-index: 1;
	height: 50px;
	width: 400px;
	margin-top: -40px;
	background-color: #fff;
}

 .downloads li{
   display: block;
   padding-bottom: 3px;
   padding-top: 3px;
   border-top: 2px solid rgba(250, 184, 79, 0.85);
 
 }
 
 .downloads li:hover{

 background-color: rgba(250, 184, 79, 0.15);

 }
 
 .downloadhead {
 	font-weight: 600;
 }

